import { getMediaImageData } from '@innedit/innedit-react';
import classnames from 'classnames';
import React, { FC, SyntheticEvent } from 'react';

const Item: FC<{
  index: number;
  ratios: { [key: number]: number };
  onClick: (event: SyntheticEvent<HTMLDivElement>) => void;
  value: any;
}> = function ({ index, onClick, ratios, value }) {
  const beta = 1.2;

  const ratioIndex = (idx: number): boolean => ratios[idx] > beta;

  let test = false;
  if (ratioIndex(index)) {
    switch (String(index)) {
      case '0': // photo 1
        test = true;
        break;
      case '1': // photo 2
        if (!ratioIndex(0)) {
          test = true;
        }
        break;
      case '2': // photo 3
        if (!ratioIndex(0) && !ratioIndex(1)) {
          test = true;
        }
        break;
      case '3': // photo 4
        if (ratioIndex(0) || ratioIndex(1)) {
          test = true;
        }
        break;
      case '4': // photo 5
        if ((ratioIndex(0) || ratioIndex(1)) && ratioIndex(3)) {
          test = true;
        }
        break;
      case '7': // photo 8
        // if (1 === nbAvant || 3 === nbAvant) {
        //   addRatio(index, ratio);
        // }
        break;
      default:
    }
  }

  const image = getMediaImageData({
    breakpoints: [640, 750, 1080],
    media: value,
    transformations: [test ? 'r_1.5' : 'r_0.8'],
  });

  // const getImage = useCallback(() => {
  //   getGatsbyImageData({
  //     breakpoints: [640, 750, 1080],
  //     path: value.pathname,
  //     transformations: [test ? 'r_1.5' : 'r_0.8'],
  //   })
  //     .then((result: IGatsbyImageData) => setImage(result))
  //     .catch(() => null);
  // }, [test, value]);
  //
  // useEffect(() => {
  //   getImage();
  // }, [getImage]);

  return (
    <div
      className={classnames({
        'col-span-2': test,
      })}
      data-index={index}
      onClick={onClick}
      role="presentation"
    >
      {image && (
        <img
          alt={value.type || value.fullPath}
          sizes={image.images.sizes}
          src={image.images.src}
          srcSet={image.images.srcSet}
        />
      )}
    </div>
  );
};

//        <GatsbyImage alt={value.pathname} image={image} objectFit="cover" />

export default Item;
// export default memo(
//   Item,
//   (prev, next) =>
//     prev.value === next.value && prev.nbPaysage === next.nbPaysage,
// );
