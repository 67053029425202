import { ProduitModel } from '@innedit/innedit-react';
import { ProduitNode } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../utils/Modal';
import Title from '../Title';
import Achat from './Achat';
import Demande from './Demande';
import Dimensions from './Dimensions';
import Fullscreen from './Fullscreen';
import Photos from './Photos';
import ProduitsSimilaires from './ProduitsSimilaires';

const Produit: FC<{
  data: ProduitNode;
  pathname: string;
}> = function ({ data, pathname }) {
  const [produit, setProduit] = useState<ProduitNode>(data);

  const { t } = useTranslation();
  const [showFullscreen, setShowFullscreen] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    let unsub: () => void;
    ProduitModel.watch(data.id, snapshot => {
      if (isMounted && snapshot) {
        setProduit(snapshot);
      }
    })
      .then(result => {
        unsub = result;

        return true;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [data]);

  const handleOnClick = () => {
    setShowFullscreen(show => !show);
  };

  const handleCloseOnClick = () => {
    setShowFullscreen(false);
  };

  if (produit.hidden) {
    return <div>Ce produit n'est plus visible.</div>;
  }

  const Details =
    'show' === produit.priceDisplayMode && produit.price ? Achat : Demande;

  const { qtyMin, qtyMax, qtyAvailable } = produit;

  const description = produit.traductions?.en?.description
    ? produit.traductions.en.description
    : produit.description;

  return (
    <article>
      {showFullscreen && (
        <Modal>
          <Fullscreen closeOnClick={handleCloseOnClick} produit={produit} />
        </Modal>
      )}
      <div className="flex flex-col-reverse md:flex-row md:space-x-12">
        {produit.medias && (
          <div className="flex-w-3/5">
            <Photos medias={produit.medias} onClick={handleOnClick} />
          </div>
        )}

        <div className="flex-w-2/5 mb-12 md:mb-0">
          <div className="flex flex-col sticky top-6">
            <Title
              as="h1"
              className={classnames(
                'flex-auto text-xl md:text-3xl text-dark-500 leading-normal',
                // 'md:truncate overflow-ellipsis overflow-hidden max-w-full',
              )}
            >
              {produit.traductions?.en?.name ?? produit.name}
            </Title>

            {produit.hasInventory &&
              produit.qtyAvailable > 0 &&
              produit.qtyReserved > 0 &&
              produit.qtyAvailable === produit.qtyReserved && (
                <p className="text-red-500">{t('product.is-reserved.label')}</p>
              )}
            {produit.hasInventory && 0 === produit.qtyAvailable && (
              <p className="text-red-500">{t('product.is-sold.label')}</p>
            )}
            {description && (
              <div
                className="my-5 text-justify text-dark-400"
                dangerouslySetInnerHTML={{
                  __html: description as string,
                }}
              />
            )}

            <div className="my-5 flex flex-col space-y-3">
              {undefined !== qtyMin && qtyMin > 1 && (
                <div className="text-cello-800">
                  <span>{t(`product.min-qty.label`, { count: qtyMin })}</span>
                </div>
              )}
              {undefined !== qtyMax && qtyMax > 0 && qtyAvailable !== qtyMax && (
                <div className="text-dark-800">
                  <span>{t(`product.max-qty.label`, { count: qtyMax })}</span>
                </div>
              )}
              {undefined !== produit.qtyByNumber && produit.qtyByNumber > 1 && (
                <div className="text-dark-800">
                  <span>
                    {t('product.by-number-qty.label', {
                      count: produit.qtyByNumber,
                    })}
                  </span>
                </div>
              )}
              {produit.isALot && (
                <div className="text-dark-800">
                  <span>{t('product.unit-price.lot.label')}</span>
                </div>
              )}

              {undefined !== qtyAvailable && qtyAvailable > 1 && (
                <div className="text-dark-800">
                  <span>
                    {t('product.qty-available.label', {
                      count: qtyAvailable,
                    })}
                  </span>
                </div>
              )}
            </div>

            {(!produit.hasInventory || produit.qtyAvailable > 0) && (
              <Details produit={produit} />
            )}

            <Dimensions produit={produit} />
            {produit.sku && (
              <div className="mt-6">
                <h3>{t('product.infos.renseignements.label')}</h3>
                <div className="grid grid-cols-2">
                  <span>{t('product.sku.label')}</span>
                  <span>{produit.sku}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProduitsSimilaires pathname={pathname} produit={produit} />
    </article>
  );
};

export default Produit;
