import { ProduitNode } from '@innedit/innedit-type';
import React, { FC } from 'react';

const ShowPrice: FC<{
  produit: ProduitNode;
}> = function ({ produit }) {
  const { hasInventory, qtyAvailable } = produit;

  const newPrice = produit.price || 0;

  const achatPossible = !hasInventory || (qtyAvailable && qtyAvailable > 0);

  if (!achatPossible) {
    return null;
  }

  if (!newPrice || newPrice <= 0) {
    return null;
  }

  return (
    <div>
      {newPrice && <span className="price text-2xl">{newPrice} €</span>}
    </div>
  );
};

const PrixCmp: FC<{
  className?: string;
  produit: ProduitNode;
}> = function (props) {
  const {
    className,
    produit: { hasInventory, kind, qtyAvailable },
  } = props;

  return (
    <div className={className}>
      <ShowPrice {...props} />
      {'good' === kind && hasInventory && 0 === qtyAvailable && (
        <span className="font-bold ml-6">Vendu</span>
      )}
    </div>
  );
};

export default PrixCmp;
