import { MediaNode } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

import Item from './Item';

const PhotosSC = styled.div``;

const Photos: FC<{
  onClick: (event: SyntheticEvent<HTMLDivElement>) => void;
  medias: MediaNode[];
}> = function ({ onClick, medias }) {
  const [ratios] = useState<{ [key: number]: number }>(
    medias.reduce((acc, value, index) => {
      const newAcc: { [key: number]: number } = { ...acc };
      newAcc[index] =
        value.width && value.height ? value.width / value.height : 1;

      return newAcc;
    }, {}),
  );

  return (
    <PhotosSC className="grid grid-cols-2 gap-4">
      {medias.slice(0, 12).map((value, index) => (
        <Item
          key={objectHash(value)}
          index={index}
          onClick={onClick}
          ratios={ratios}
          value={value}
        />
      ))}
    </PhotosSC>
  );
};

export default Photos;
