import { ProduitNode } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DimensionsStandard from './Standard';

const Dimensions: FC<{ produit: ProduitNode }> = function ({ produit }) {
  const { t } = useTranslation();
  const calculInch = (value: number) => Math.round((value / 2.54) * 100) / 100;

  const dimensions = [];
  if (produit.weight && produit.weight > 0) {
    dimensions.push(
      <span className="col-span-4">
        {t('product.weight.label', { count: produit.weight })}
      </span>,
    );
  }
  if (produit.isFragile) {
    dimensions.push(
      <span className="col-span-4">{t('product.is-fragile.label')}</span>,
    );
  }
  if (produit.dimensions && produit.dimensions.length > 0) {
    produit.dimensions.forEach((dimension, index) => {
      if (dimension.libelle && dimension.valeur && dimension.valeur !== '0') {
        dimensions.push(
          <>
            <span className="col-span-2">{dimension.libelle}</span>
            <span>{calculInch(Number(dimension.valeur))} inch</span>
            <span>{dimension.valeur} cm</span>
          </>,
        );
      }
    });
  }

  return (
    <div className="mt-6">
      <h3>{t('product.infos.dimensions.label')}</h3>
      <div className="text-cello-800 grid grid-cols-4 whitespace-nowrap">
        <DimensionsStandard produit={produit} />
        {dimensions}
      </div>
    </div>
  );
};

export default Dimensions;
