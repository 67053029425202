import { ProduitModel } from '@innedit/innedit-react';
import { ProduitNode } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Catalogue from '../Produits';

interface ProduitsSimilairesProps {
  pathname: string;
  produit: ProduitNode;
}
const ProduitsSimilaires: FC<ProduitsSimilairesProps> = function ({
  pathname,
  produit: { id, name, searchSimilars },
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [produits, setProduits] = useState<ProduitNode[]>();

  useEffect(() => {
    let isMounted = true;

    const q = name || '';
    const qTabs = q.split(' ');
    const wheres: { [key: string]: any } = {
      boutique: String(process.env.GATSBY_ID_BOUTIQUE),
      hidden: false,
      isOnSale: true,
      'NOT objectID': id,
    };

    // if (category) {
    //   wheres.category = category;
    // }

    ProduitModel.search(searchSimilars || qTabs.slice(0, 5).join(' '), {
      language,
      wheres,
      nbParPage: 9,
    })
      .then(action => {
        if (isMounted) {
          setProduits(
            action.hits.map(hit => ({
              ...hit,
              id: hit.objectID,
            })),
          );
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [id, language, name, searchSimilars]);

  if (!produits || 0 === produits.length) {
    return null;
  }

  return (
    <div className="mt-24">
      <h3 className="text-2xl text-center">
        {t('product.infos.produits-similaires')}
      </h3>
      <Catalogue
        hideHeader
        hideSearch
        items={produits}
        itemsClassName="md:grid md:grid-cols-3 lg:grid-cols-4 gap-6 gap-y-12 md:gap-12"
        pathname={pathname}
        totalCount={produits.length}
      />
    </div>
  );
};

export default ProduitsSimilaires;
