import { InneditContext } from '@innedit/innedit-react';
import { ProduitNode } from '@innedit/innedit-type';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Button';

const Demande: FC<{ produit: ProduitNode }> = ({ produit }) => {
  const { t } = useTranslation();
  const { addToDemande, demande } = useContext(InneditContext);
  if (!produit) {
    return null;
  }

  const thumbnail =
    produit.medias && produit.medias.length > 0
      ? `${produit.medias[0].origin}/${produit.medias[0].pathname}`
      : undefined;

  const handleOnClick = () => {
    addToDemande({
      thumbnail,
      id: produit.id,
      name: produit.name,
      price: produit.price || -1,
      quantity: 1,
    });
  };

  return (
    <div className="flex space-x-3 max-w mx-auto">
      {demande.length > 0 && (
        <Button
          className="flex-1"
          status="neutral"
          to={t('pages.contact.pathname')}
        >
          {t('product.infos.voir-demande.label')}
        </Button>
      )}
      <Button
        className="box-border focus:outline-none"
        onClick={handleOnClick}
        to={"/contact/"}
      >
        {t('product.request.button.label')}
      </Button>
    </div>
  );
};

export default Demande;
