import { ProduitNode } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DimensionsStandard: FC<{ produit: ProduitNode }> = function ({
  produit,
}) {
  const { t } = useTranslation();

  const calculInch = (value: number) => Math.round((value / 2.54) * 100) / 100;

  return (
    <>
      {undefined !== produit.width && produit.width > 0 && (
        <>
          <span className="col-span-2">
            {t('product.infos.dimensions.width.label')}
          </span>
          <span>{`${calculInch(produit.width)} inch`}</span>
          <span>{produit.width} cm</span>
        </>
      )}

      {undefined !== produit.depth && produit.depth > 0 && (
        <>
          <span className="col-span-2">
            {t('product.infos.dimensions.depth.label')}
          </span>
          <span>{`${calculInch(produit.depth)} inch`}</span>
          <span>{produit.depth} cm</span>
        </>
      )}

      {undefined !== produit.height && produit.height > 0 && (
        <>
          <span className="col-span-2">
            {t('product.infos.dimensions.height.label')}
          </span>
          <span>{`${calculInch(produit.height)} inch`}</span>
          <span>{produit.height} cm</span>
        </>
      )}
    </>
  );
};

export default DimensionsStandard;
