import styled from 'styled-components';

import prev from '../../../images/arrow-alt-circle-left.svg';
import next from '../../../images/arrow-alt-circle-right.svg';
import arrows from '../../../images/arrows-alt-solid.svg';
import compressArrows from '../../../images/compress-arrows-alt-solid.svg';

export default styled.div<{ fit: string }>`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 0 0 auto;

  .stretch {
    position: absolute;
    top: 0;
    left: 13px;
    background: 0 0;
    border: 0;
    justify-self: center;
    outline: 0;
    padding: 16px 8px;
    cursor: pointer;
    color: white;

    &:after {
      display: block;
      content: '';
      mask-repeat: no-repeat;
      mask-position: 100% 100%;
      mask-image: ${(props: { fit: string }) =>
        'cover' === props.fit ? `url(${compressArrows})` : `url(${arrows})`};
      background-color: rgba(255, 255, 255, 0.4);
      width: 20px;
      height: 20px;
    }
  }

  .previous,
  .next {
    background: 0 0;
    border: 0;
    justify-self: center;
    outline: 0;
    padding: 16px 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 13px;
    color: white;

    span {
      display: none;
    }

    cursor: pointer;

    &:after {
      display: block;
      content: '';
      mask: url(${prev}) no-repeat 100% 100%;
      background-color: rgba(255, 255, 255, 0.4);
      width: 20px;
      height: 20px;
    }

    &:hover {
      &:after {
        background-color: white;
      }
    }
  }

  .next {
    left: auto;
    right: 13px;

    &:after {
      mask: url(${next}) no-repeat 100% 100%;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    .previous,
    .next {
      display: none;
    }
  }
`;
