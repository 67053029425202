import { ProduitNode } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent } from 'react';

import IconClose from '../../icons/Close';
import colors from '../../styles/colors';

const Fullscreen: FC<{
  closeOnClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
  produit: ProduitNode;
}> = function ({ closeOnClick, produit }) {
  const { medias } = produit;

  if (!medias) {
    return null;
  }

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-light-100 overflow-auto">
      <div className="m-3 flex flex-col space-y-3">
        {medias.map(media => (
          <img
            key={objectHash(media)}
            alt={media.name}
            className="w-full"
            src={`${media.origin}/${media.pathname}`}
          />
        ))}
      </div>

      <button
        className="fixed top-0 right-0 outline-none focus:outline-none h-24 w-24"
        onClick={closeOnClick}
        type="button"
      >
        <IconClose color={colors.dark[50]} />
      </button>
      {/* <button */}
      {/*  className="absolute bottom-6 right-6 outline-none focus:outline-none" */}
      {/*  onClick={fullscreenOnClick} */}
      {/*  type="button" */}
      {/* > */}
      {/*  Plein écran */}
      {/* </button> */}
    </div>
  );
};

export default Fullscreen;
