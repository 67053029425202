import { ProduitNode } from '@innedit/innedit-type';
import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Content from '../../components/Content';
import Layout from '../../components/Layout';
import Produit from '../../components/Produit';

const PageFrProduitTemplate: FC<PageProps<{ produit: ProduitNode }>> =
  function (props) {
    const {
      data: { produit },
      path,
    } = props;

    const { t } = useTranslation();

    const { description, medias, metaDescription, metaKeywords, name, title } =
      produit;

    const image =
      medias && medias.length > 0
        ? `${medias[0].origin}/w_1080,h_1080/${medias[0].pathname}`
        : undefined;

    const breadcrumbs =
      !produit.hasInventory || produit.qtyAvailable > 0
        ? [
            {
              mobile: true,
              to: t('pages.inventaire.pathname'),
              value: t('pages.inventaire.title'),
            },
          ]
        : [
            {
              mobile: true,
              to: t('footer.categories.values.vendus.pathname'),
              value: t('footer.categories.values.vendus.label'),
            },
          ];

    const layoutProps = {
      breadcrumbs,
      image,
      breadcrumbsTitle: produit.traductions?.en?.name
        ? (produit.traductions.en.name as string)
        : produit.name,
      description: metaDescription || description,
      htmlAttributes: {
        prefix:
          'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# product: http://ogp.me/ns/product#',
      },
      keywords: metaKeywords,
      pathname: path,
      title: title || name,
      titleClassName:
        'max-w-screen-lg my-20 mx-12 md:mx-auto text-3xl md:text-5xl text-center',
      type: 'og:product',
    };

    return (
      <Layout {...layoutProps}>
        <Content>
          <Produit data={produit} pathname={path} />
        </Content>
      </Layout>
    );
  };

export default PageFrProduitTemplate;

export const query = graphql`
  query ($id: String!) {
    produit(id: { eq: $id }) {
      id
      condition
      depth
      description
      hasInventory
      height
      hidden
      isALot
      isOnSale
      medias {
        id
        origin
        pathname
        height
        width
      }
      name
      price
      priceDisplayMode
      qtyAvailable
      qtyByNumber
      qtyMax
      qtyMin
      shippingMode
      shortname
      sku
      title
      traductions {
        en {
          description
          name
          shortname
        }
      }
      weight
      width
    }
  }
`;
