import styled from 'styled-components';

export { default as Navigation } from './Navigation';

export const Achat = styled.div`
  > div {
    display: flex;
  }

  button {
    flex: 1;
    margin-right: 0;
  }

  button + button {
    margin-left: 1.5rem;
  }

  .commentaire {
    display: block;
    color: #ddb884;
  }

  .livraison {
    display: block;
    font-size: 12px;
    font-style: italic;

    a {
      color: inherit;
    }

    span {
      display: block;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoint}) {
    margin-top: 1.5rem;

    button {
      margin: 0.75rem 0 !important;
    }

    button + button {
      margin-left: 0;
    }
  }
`;

export const Demande = styled.div``;

export const Vendu = styled.article``;
