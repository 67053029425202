import { InneditContext, ProduitModel } from '@innedit/innedit-react';
import { ProduitNode } from '@innedit/innedit-type';
import { navigate } from 'gatsby';
import React, {
  FC,
  memo,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Button';
import { Achat as AchatSC } from '../styles';
import Prix from './Prix';

const Achat: FC<{ produit: ProduitNode }> = function ({ produit }) {
  const { t } = useTranslation();
  const [isInCart, setIsInCart] = useState<boolean>(false);
  const [qty, setQty] = useState(1);
  const [oldQty, setOldQty] = useState(1);
  const { addToCart, cart } = useContext(InneditContext);

  const thumbnail =
    produit.medias && produit.medias.length > 0
      ? `${produit.medias[0].origin}/w_1080,h_1080/${produit.medias[0].pathname}`
      : undefined;

  const changeQty = (q: number) => {
    const newQty = ProduitModel.calculNewQuantity(q, oldQty, produit);
    setQty(newQty);
  };

  const handleAddToCart = (event: Event) => {
    event.preventDefault();

    if (produit && produit.price) {
      addToCart({
        thumbnail,
        id: produit.id,
        name: produit.name,
        price: produit.price,
        quantity: qty,
      });

      navigate(t('pages.panier.pathname'));
    } else {
      console.error("le produit n'existe pas ou le prix n'est pas renseigné");
    }
  };

  const handleUpdateToCart = (event: Event) => {
    event.preventDefault();

    if (produit && produit.price) {
      addToCart({
        thumbnail,
        id: produit.id,
        name: produit.name,
        price: produit.price,
        quantity: 0,
      });

      navigate(t('pages.panier.pathname'));
    } else {
      console.error("le produit n'existe pas ou le prix n'est pas renseigné");
    }
  };

  const handleOnChangeQty = (event: SyntheticEvent<HTMLInputElement>) => {
    setOldQty(qty);
    setQty(parseInt(event.currentTarget.value, 10));
  };

  const handleOnBlurQty = (event: SyntheticEvent<HTMLInputElement>) => {
    changeQty(parseInt(event.currentTarget.value, 10));
  };

  useEffect(() => {
    setQty(produit.qtyByNumber || 1);
  }, [produit]);

  useEffect(() => {
    let tmp = false;
    if (cart && cart.length > 0) {
      const index = cart.findIndex(item => item.id === produit.id);

      if (index >= 0) {
        tmp = true;
      }
    }

    setIsInCart(tmp);
  }, [cart, produit]);

  return (
    <>
      {produit && (
        <Prix className="flex-auto text-neutral-700" produit={produit} />
      )}
      <AchatSC className="flex flex-col mt-10">
        {(!produit.hasInventory ||
          (produit.qtyAvailable > 0 &&
            (undefined === produit.qtyReserved ||
              0 === produit.qtyReserved ||
              produit.qtyAvailable > produit.qtyReserved))) && (
          <div>
            {produit.hasInventory &&
              (undefined === produit.qtyMax || produit.qtyMax > 1) && (
                <input
                  className="mr-3 border w-20 h-14 text-center border-boulder-500 outline-none box-border leading-10"
                  max={produit.qtyMax}
                  min={produit.qtyMin}
                  onBlur={handleOnBlurQty}
                  onChange={handleOnChangeQty}
                  type="number"
                  value={qty}
                />
              )}
            <Button onClick={isInCart ? handleUpdateToCart : handleAddToCart}>
              {t(
                isInCart
                  ? 'product.cart.update.label'
                  : 'product.cart.add.label',
              )}
            </Button>
          </div>
        )}
      </AchatSC>
    </>
  );
};

export default memo(Achat);
